import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SnippetsList from "../components/snippets-list"
import TitleSection from "../components/title-section"

class SnippetsIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const tagsGroup = data.tagsGroup.group

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <TitleSection title="Snippets" />
        <SEO />

        {/* Posts list */}
        <SnippetsList nodes={posts} tagsGroup={tagsGroup} />

      </Layout>
    )
  }
}

export default SnippetsIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, filter: {fields: {slug: {regex: "//snippets//"}}}) {
      edges {
        node {
          excerpt
          html
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            update(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
    tagsGroup: allMarkdownRemark(filter: {fields: {slug: {regex: "//snippets//"}}}) {
      group(field: frontmatter___tags) {
        name: fieldValue
        count: totalCount
      }
    }
  }
`
